exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inqovi-access-information-js": () => import("./../../../src/pages/inqovi/access-information.js" /* webpackChunkName: "component---src-pages-inqovi-access-information-js" */),
  "component---src-pages-inqovi-product-information-js": () => import("./../../../src/pages/inqovi/product-information.js" /* webpackChunkName: "component---src-pages-inqovi-product-information-js" */),
  "component---src-pages-inqovi-resources-for-patients-js": () => import("./../../../src/pages/inqovi/resources-for-patients.js" /* webpackChunkName: "component---src-pages-inqovi-resources-for-patients-js" */),
  "component---src-pages-lonsurf-access-information-js": () => import("./../../../src/pages/lonsurf/access-information.js" /* webpackChunkName: "component---src-pages-lonsurf-access-information-js" */),
  "component---src-pages-lonsurf-product-information-js": () => import("./../../../src/pages/lonsurf/product-information.js" /* webpackChunkName: "component---src-pages-lonsurf-product-information-js" */),
  "component---src-pages-lonsurf-resources-for-patients-js": () => import("./../../../src/pages/lonsurf/resources-for-patients.js" /* webpackChunkName: "component---src-pages-lonsurf-resources-for-patients-js" */),
  "component---src-pages-lytgobi-access-information-js": () => import("./../../../src/pages/lytgobi/access-information.js" /* webpackChunkName: "component---src-pages-lytgobi-access-information-js" */),
  "component---src-pages-lytgobi-product-information-js": () => import("./../../../src/pages/lytgobi/product-information.js" /* webpackChunkName: "component---src-pages-lytgobi-product-information-js" */),
  "component---src-pages-lytgobi-resources-for-patients-js": () => import("./../../../src/pages/lytgobi/resources-for-patients.js" /* webpackChunkName: "component---src-pages-lytgobi-resources-for-patients-js" */)
}

